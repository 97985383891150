export default class InspectionResponse {
	static no = 0;
	static yes = 1;
	static notApplicable = 2;

	static options = [
		{ id: this.no, name: 'No' },
		{ id: this.yes, name: 'Yes' },
		{ id: this.notApplicable, name: 'N/A' },
	];

	static display(value) {
		const item = this.options.find(x => x.id === value);
		return item ? item.name : '';
	}

	static isValid(value) {
		return value === this.no || value === this.yes || value === this.notApplicable;
	}
}
