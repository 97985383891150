import CustomerRental from './CustomerRental.js';
import { getDateTimeFromDto as getDate, getObjectFromDto, getValueFromDto as getValue } from './_helpers.js';

export default class RentalItemSchedule {
	constructor(dto) {
		this.id = getValue(dto, 'id', 'number', 0);
		this.customerRental = getObjectFromDto(dto, 'customerRental', CustomerRental, null);
		this.customerRentalId = getValue(dto, 'customerRentalId', 'number', null);
		this.rentalItemId = getValue(dto, 'rentalItemId', 'number', null);
		this.rentalItem = null;
		this.startDate = getDate(dto, 'startDate', null);
		this.endDate = getDate(dto, 'endDate', null);
		Object.defineProperty(this, 'rentalItem', { enumerable: false });
	}
}
