export default class PaymentType {
	static cash = 1;
	static check = 2;
	static creditCard = 3;
	static accountBalance = 4;

	static options = [
		{ id: this.cash, name: 'Cash' },
		{ id: this.check, name: 'Check' },
		{ id: this.creditCard, name: 'Credit card' },
		{ id: this.accountBalance, name: 'Account balance' },
	];

	static allIds = this.options.map(x => x.id);

	static display(value) {
		const item = this.options.find(x => x.id === value);
		return item ? item.name : '';
	}

	static displayArray(terms) {
		if (Array.isArray(terms) && terms.length > 0) {
			return terms.map(x => this.display(x)).join(', ');
		} else {
			return '';
		}
	}

	static isValid(value) {
		return value === this.cash || value === this.check || value === this.creditCard || value === this.accountBalance;
	}
}
