export default class InvoiceDateType {
	static completion = 0;
	static endOfMonth = 1;
	static custom = 2;

	static options = [
		{ id: this.completion, name: 'Completion' },
		{ id: this.endOfMonth, name: 'End of month' },
		{ id: this.custom, name: 'Custom' },
	];

	static display(value) {
		const item = this.options.find(x => x.id === value);
		return item ? item.name : '';
	}

	static isValid(value) {
		return value === this.completion || value === this.endOfMonth || value === this.custom;
	}
}
