import ContactDetailType from './ContactDetailType.js';

const cleanPhoneFormat = /^\+1\d{10}$/;

function cleanPhone(input) {
	if (input == null) {
		input = '';
	}
	if (cleanPhoneFormat.test(input)) {
		return input;
	}
	let cleaned = input.replace(/[^\d]/g, '');
	// +12625749400
	if ( cleaned.length == 10 ) {
		return '+1' + cleaned;
	} else if ( cleaned.length == 11 ) {
		return '+' + cleaned;
	} else {
		return input;
	}
}

function formatPhone(input) {
	input = cleanPhone(input);
	if (cleanPhoneFormat.test(input)) {
		return input.substr(2, 3) + '-' + input.substr(5, 3) + '-' + input.substr(8);
	} else {
		return input;
	}
}

export default class ContactDetail {
	constructor(dto) {
		this.id = 0;
		this.label = '';
		this.value = '';
		this.sortOrder = 0;
		this.type = null;

		if (typeof dto === 'object' && dto !== null) {
			if (typeof dto.id === 'number') {
				this.id = dto.id;
			}
			if (typeof dto.label === 'string') {
				this.label = dto.label;
			}
			if (ContactDetailType.isValid(dto.type)) {
				this.type = dto.type;
			}
			if (typeof dto.value === 'string') {
				if (this.type === ContactDetailType.phoneNumber || this.type === ContactDetailType.faxNumber) {
					this.value = formatPhone(dto.value);
				} else {
					this.value = dto.value;
				}
			}
			if (typeof dto.sortOrder === 'number') {
				this.sortOrder = dto.sortOrder;
			}
		}
	}
}
