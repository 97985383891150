import { getDateTimeFromDto as getDate, getObjectFromDto as getObject, getValueFromDto as getValue } from './_helpers.js';
import Customer from './Customer';
import Duration from './Duration.js';
import WorkOrder from './WorkOrder.js';

export default class WorkOrderSchedule {
	constructor(dto) {
		this.id = getValue(dto, 'id', 'number', 0);
		this.interval = getObject(dto, 'interval', Duration, new Duration());
		this.notes = getValue(dto, 'notes', 'string', null);
		this.isPaused = getValue(dto, 'isPaused', 'boolean', false);
		this.active = getValue(dto, 'active', 'boolean', true);
		this.hasError = getValue(dto, 'hasError', 'boolean', false);
		Object.defineProperty(this, 'customer', { enumerable: false, value: null, writable: true });
		this.customer = getObject(dto, 'customer', Customer, null);
		this.customerLocationId = getValue(dto, 'customerLocationId', 'number', null);
		Object.defineProperty(this, 'customerLocationId', { enumerable: false });
		Object.defineProperty(this, 'lastOccurrence', { enumerable: false, value: null, writable: true });
		this.lastOccurrence = getObject(dto, 'lastOccurrence', WorkOrder, null);
		Object.defineProperty(this, 'nextOccurrence', { enumerable: false, value: null, writable: true });
		this.nextOccurrence = getObject(dto, 'nextOccurrence', WorkOrder, null);
		this.endDate = getDate(dto, 'endDate', null);
	}

	get nextOccurrenceDate() {
		if (this.nextOccurrence) {
			return this.nextOccurrence.scheduledStartTime;
		} else if (this.lastOccurrence) {
			return this.lastOccurrence.workStartTime.plus(this.interval.toLuxonDuration());
		} else {
			return null;
		}
	}

	get serviceItems() {
		if (this.nextOccurrence !== null) {
			return this.nextOccurrence.serviceItems;
		} else if (this.lastOccurrence !== null) {
			return this.lastOccurrence.serviceItems;
		} else {
			return [];
		}
	}

	get inventoryItems() {
		if (this.nextOccurrence !== null) {
			return this.nextOccurrence.inventoryItems;
		} else if (this.lastOccurrence !== null) {
			return this.lastOccurrence.inventoryItems;
		} else {
			return [];
		}
	}

	get rentalOperations() {
		if (this.nextOccurrence !== null) {
			return this.nextOccurrence.rentalOperations;
		} else if (this.lastOccurrence !== null) {
			return this.lastOccurrence.rentalOperations;
		} else {
			return [];
		}
	}

	get customerLocation() {
		if (this.customerLocationId > 0 && this.customer && Array.isArray(this.customer.locations)) {
			return this.customer.locations.find(x => x.id === this.customerLocationId);
		} else {
			return null;
		}
	};
}
