export default class TimeType {
	static anyTime = 0;
	static afternoon = 1;
	static morning = 2;
	static specificTime = 3;

	static options = [
		{ id: this.anyTime, name: 'Any time' },
		{ id: this.afternoon, name: 'Afternoon' },
		{ id: this.morning, name: 'Morning' },
		{ id: this.specificTime, name: 'Specific Time' },
	];

	static isValid(value) {
		return value === this.anyTime || value === this.afternoon || value === this.morning || value === this.specificTime;
	}
}
