export default class ContactDetailType {
	static phoneNumber = 0;
	static faxNumber = 1;
	static emailAddress = 2;

	static options = [
		{ id: this.phoneNumber, name: 'Phone number' },
		{ id: this.faxNumber, name: 'Fax number' },
		{ id: this.emailAddress, name: 'Email address' },
	];

	static display(value, number) {
		const item = this.options.find(x => x.id === value);
		return item ? (item.name + (number === 1 ? '' : 's')) : '';
	}

	static isValid(value) {
		return value === this.phoneNumber || value === this.faxNumber || value === this.emailAddress;
	}
}
