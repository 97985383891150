import RentalOperation from './RentalOperation.js';
import WorkOrderRentalItem from './WorkOrderRentalItem.js';
import { getArrayOfObjectsFromDto as getTypedArray, getValueFromDto as getValue } from './_helpers.js';

export default class WorkOrderRentalOperation {
	constructor(dto) {
		this.id = getValue(dto, 'id', 'number', 0);
		this.rentalItemTypeId = getValue(dto, 'rentalItemTypeId', 'number', null);
		this.quantity = getValue(dto, 'quantity', 'number', 1);
		this.operation = getValue(dto, 'operation', 'number', RentalOperation.deliver);
		this.rentalItems = getTypedArray(dto, 'rentalItems', WorkOrderRentalItem, []);
	}
}
