export default class BillingMethod {
	static unspecified = 0;
	static email = 1;
	static mail = 2;

	static options = [
		{ id: this.unspecified, name: 'Unspecified' },
		{ id: this.email, name: 'Email' },
		{ id: this.mail, name: 'Mail' },
	];

	static display(value) {
		const item = this.options.find(x => x.id === value);
		return item ? item.name : '';
	}

	static isValid(value) {
		return value === this.unspecified || value === this.email || value === this.mail;
	}
}
