export default class CustomerTank {
	constructor(dto) {
		this.id = 0;
		this.tankTypeId = null;
		this.capacity = null;
		this.material = '';
		this.description = '';

		if (typeof dto === 'object' && dto !== null) {
			if (typeof dto.id === 'number') {
				this.id = dto.id;
			}
			if (typeof dto.tankTypeId === 'number') {
				this.tankTypeId = dto.tankTypeId;
			}
			if (typeof dto.capacity === 'number') {
				this.capacity = dto.capacity;
			}
			if (typeof dto.material === 'string') {
				this.material = dto.material;
			}
			if (typeof dto.description === 'string') {
				this.description = dto.description;
			}
		}
	}
}
