import PaymentType from './PaymentType.js';

export default class PaymentTerm {
	constructor(dto) {
		this.id = 0;
		this.name = '';
		this.daysUntilDue = 0;
		this.lateFeeInterestRate = 0;
		this.acceptedPaymentTypes = [];
		this.sortOrder = 0;

		if (typeof dto === 'object' && dto !== null) {
			if (typeof dto.id === 'number') {
				this.id = dto.id;
			}
			if (typeof dto.name === 'string') {
				this.name = dto.name;
			}
			if (typeof dto.daysUntilDue === 'number') {
				this.daysUntilDue = dto.daysUntilDue;
			}
			if (typeof dto.lateFeeInterestRate === 'number') {
				this.lateFeeInterestRate = dto.lateFeeInterestRate;
			}
			if (typeof dto.acceptedPaymentTypes === 'object' && Array.isArray(dto.acceptedPaymentTypes)) {
				// ensure no duplicates and no account balance
				const set = new Set(dto.acceptedPaymentTypes);
				if (set.has(PaymentType.accountBalance)) {
					set.delete(PaymentType.accountBalance);
				}
				this.acceptedPaymentTypes = Array.from(set);
			}
			if (typeof dto.sortOrder === 'number') {
				this.sortOrder = dto.sortOrder;
			}
		}
	}
}
