import { getArrayOfObjects2FromDto as getTypedArray, getValueFromDto as getValue } from './_helpers';

export default class PaginatedList {
	constructor(dto, constructor) {
		this.data = getTypedArray(dto, 'data', constructor, []);
		this.start = getValue(dto, 'start', 'number', 0);
		this.limit = getValue(dto, 'limit', 'number', 0);
		this.totalCount = getValue(dto, 'totalCount', 'number', 0);
	}

	get hasNextPage() {
		return this.totalCount > this.start + this.limit;
	}

	get hasPreviousPage() {
		return this.start > 0;
	}
}
