import CustomerTank from './CustomerTank.js';
import FileReference from './FileReference.js';

export default class CustomerLocation {
	constructor(dto) {
		this.id = 0;
		this.name = '';
		this.street1 = '';
		this.street2 = '';
		this.city = '';
		this.township = '';
		this.county = '';
		this.state = '';
		this.postalCode = '';
		this.country = '';
		this.latitude = 0;
		this.longitude = 0;
		this.directions = '';
		this.notes = '';
		this.attachments = [];
		this.contacts = [];
		this.tanks = [];

		if (typeof dto === 'object' && dto !== null) {
			if (typeof dto.id === 'number') {
				this.id = dto.id;
			}
			if (typeof dto.name === 'string') {
				this.name = dto.name;
			}
			if (typeof dto.street1 === 'string') {
				this.street1 = dto.street1;
			}
			if (typeof dto.street2 === 'string') {
				this.street2 = dto.street2;
			}
			if (typeof dto.city === 'string') {
				this.city = dto.city;
			}
			if (typeof dto.township === 'string') {
				this.township = dto.township;
			}
			if (typeof dto.county === 'string') {
				this.county = dto.county;
			}
			if (typeof dto.state === 'string') {
				this.state = dto.state;
			}
			if (typeof dto.postalCode === 'string') {
				this.postalCode = dto.postalCode;
			}
			if (typeof dto.country === 'string') {
				this.country = dto.country;
			}
			if (typeof dto.latitude === 'number') {
				this.latitude = dto.latitude;
			}
			if (typeof dto.longitude === 'number') {
				this.longitude = dto.longitude;
			}
			if (typeof dto.directions === 'string') {
				this.directions = dto.directions;
			}
			if (typeof dto.notes === 'string') {
				this.notes = dto.notes;
			}
			if (typeof dto.attachments === 'object' && Array.isArray(dto.attachments) && dto.attachments.length > 0) {
				this.attachments = dto.attachments.map(x => new FileReference(x));
			}
			if (typeof dto.contacts === 'object' && Array.isArray(dto.contacts) && dto.contacts.length > 0) {
				this.contacts = dto.contacts;
			}
			if (typeof dto.tanks === 'object' && Array.isArray(dto.tanks) && dto.tanks.length > 0) {
				this.tanks = dto.tanks.map(x => new CustomerTank(x));
			}
		}
	}

	get address() {
		return (this.street1 ? this.street1 + ', ' : '') + (this.street2 ? this.street2 + ', ' : '') + (this.city ? this.city + ', ' : '') + this.state + ' ' + this.postalCode;
	}

	get addressLine1() {
		return (this.street1 ? this.street1 + ', ' : '') + (this.street2 ? this.street2 + ', ' : '')
	}

	get addressLine2() {
		return (this.city ? this.city + ', ' : '') + this.state + ' ' + this.postalCode;
	}
}
