import ContactDetail from './ContactDetail.js';
import { getArrayOfObjectsFromDto as getTypedArray, getValueFromDto as getValue } from './_helpers.js';

export default class Contact {
	constructor(dto) {
		this.id = getValue(dto, 'id', 'number',0);
		this.prefix = getValue(dto, 'prefix', 'string','');
		this.firstName = getValue(dto, 'firstName', 'string','');
		this.middleInitial = getValue(dto, 'middleInitial', 'string','');
		this.lastName = getValue(dto, 'lastName', 'string','');
		this.suffix = getValue(dto, 'suffix', 'string','');
		this.title = getValue(dto, 'title', 'string','');
		this.phoneNumbers = getTypedArray(dto, 'phoneNumbers', ContactDetail, []);
		this.faxNumbers = getTypedArray(dto, 'faxNumbers', ContactDetail, []);
		this.emailAddresses = getTypedArray(dto, 'emailAddresses', ContactDetail, []);
	}

	get fullName() {
		const middle = this.middleInitial ? this.middleInitial + '.' : '';
		return `${this.prefix} ${this.firstName} ${middle} ${this.lastName} ${this.suffix}`.replaceAll('  ', ' ').trim();
	}
}
