import InvoiceDateType from '@/models/InvoiceDateType';
import BillingMethod from './BillingMethod.js';
import Contact from './Contact.js';
import ContactDetailType from './ContactDetailType.js';
import CustomerLocation from './CustomerLocation.js';
import PaymentTerm from './PaymentTerm.js';
import { getArrayOfObjectsFromDto as getArray, getObjectFromDto as getObject, getValueFromDto as getValue } from './_helpers.js';

export default class Customer {
	constructor(dto) {
		this.id = getValue(dto, 'id', 'number', 0);
		this.number = getValue(dto, 'number', 'number', 0);
		this.name = getValue(dto, 'name', 'string', '');
		this.isResidential = getValue(dto, 'isResidential', 'boolean', true);
		this.legalDescription = getValue(dto, 'legalDescription', 'string', '');
		this.isTaxExempt = getValue(dto, 'isTaxExempt', 'boolean', false);
		this.taxIdNumber = getValue(dto, 'taxIdNumber', 'string', '');
		this.permitNumber = getValue(dto, 'permitNumber', 'string', '');
		this.notes = getValue(dto, 'notes', 'string', '');
		this.preferredOperatorId = getValue(dto, 'preferredOperatorId', 'number', null);
		this.preferredOperator = null;
		this.billingMethod = getValue(dto, 'billingMethod', 'number', BillingMethod.unspecified);
		this.billingContactId = getValue(dto, 'billingContactId', 'number', null);
		this.billingLocationId = getValue(dto, 'billingLocationId', 'number', null);
		this.isUnpaid = getValue(dto, 'isUnpaid', 'boolean', false);
		this.unpaidCount = getValue(dto, 'unpaidCount', 'number', 0);
		this.daysOverDue = getValue(dto, 'daysOverDue', 'number', 0);
		this.balanceDue = getValue(dto, 'balanceDue', 'number', 0);
		this.accountBalance = getValue(dto, 'accountBalance', 'number', 0);
		this.paymentTermId = getValue(dto, 'paymentTermId', 'number', null);
		this.paymentTerm = getObject(dto, 'paymentTerm', PaymentTerm, null);
		this.contacts = getArray(dto, 'contacts', Contact);
		this.locations = getArray(dto, 'locations', CustomerLocation);
		this.invoiceDateType = getValue(dto, 'invoiceDateType', 'number', InvoiceDateType.completion);

		Object.defineProperty(this, 'preferredOperator', { enumerable: false });
		Object.defineProperty(this, 'isUnpaid', { enumerable: false });
		Object.defineProperty(this, 'unpaidCount', { enumerable: false });
		Object.defineProperty(this, 'daysOverDue', { enumerable: false });
		Object.defineProperty(this, 'balanceDue', { enumerable: false });
		Object.defineProperty(this, 'accountBalance', { enumerable: false });
		Object.defineProperty(this, 'paymentTerm', { enumerable: false });
	}

	get billingContact() {
		return this.billingContactId === null ? null : (this.contacts.find(x => x.id === this.billingContactId) || null);
	}

	get billingLocation() {
		return this.billingLocationId === null ? null : (this.locations.find(x => x.id === this.billingLocationId) || null);
	}
}

export const rawPlaceholder = {
	id: -1,
	number: 123,
	name: "Doe, John",
	isResidential: true,
	contacts: [{
		id: -1,
		firstName: "John",
		lastName: "Doe",
		phoneNumbers: [{
			id: -1,
			label: 'Cell',
			value: '+10000000000',
			sortOrder: 0,
			type: ContactDetailType.phoneNumber,
		}],
		emailAddresses: [{
			id: -2,
			label: 'Personal',
			value: 'john.doe@example.com',
			sortOrder: 0,
			type: ContactDetailType.emailAddress,
		}]
	}],
	billingContactId: -1,
	locations: [{
		id: -1,
		name: "Home",
		street1: "123 Example St",
		street2: "",
		city: "Waukesha",
		township: "",
		county: "",
		state: "WI",
		postalCode: "53186",
		country: "US",
		contacts: [-1],
	}],
	billingLocationId: -1,
	// paymentTermId: -1,
};
export const placeholder = Object.freeze(new Customer(rawPlaceholder));
