import { round } from '@/helpers/helpers.js';
import { getValueFromDto as getValue } from './_helpers.js';

export default class WorkOrderInventoryItem {
	constructor(dto) {
		this.id = getValue(dto, 'id', 'number', 0);
		this.name = getValue(dto, 'name', 'string', null);
		this.description = getValue(dto, 'description', 'string', null);
		this.sortOrder = getValue(dto, 'sortOrder', 'number', 0);
		this.itemPrice = getValue(dto, 'itemPrice', 'number', 0);
		this.quantity = getValue(dto, 'quantity', 'number', 1);
		this.inventoryId = getValue(dto, 'inventoryId', 'number', null);
		Object.defineProperty(this, 'inventory', { enumerable: false, value: null, writable: true });
	}

	get totalPrice() {
		return round(this.itemPrice * this.quantity, 2);
	}
}
