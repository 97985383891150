export default class Inventory {
	constructor(dto) {
		this.id = 0;
		this.name = null;
		this.description = null;
		this.identifier = null;
		this.cost = 0;
		this.price = 0;
		//this.taxable = null;
		this.quantityOnHand = 0;
		this.quantityReserved = 0;
		this.lowStockWarning = 0;

		if (typeof dto === 'object' && dto !== null) {
			if (typeof dto.id === 'number') {
				this.id = dto.id;
			}
			if (typeof dto.name === 'string') {
				this.name = dto.name;
			}
			if (typeof dto.description === 'string') {
				this.description = dto.description;
			}
			if (typeof dto.identifier === 'string') {
				this.identifier = dto.identifier;
			}
			if (typeof dto.cost === 'number') {
				this.cost = dto.cost;
			}
			if (typeof dto.price === 'number') {
				this.price = dto.price;
			}
			//if (typeof dto.taxable === 'boolean') {
			//	this.taxable = dto.taxable;
			//}
			if (typeof dto.quantityOnHand === 'number') {
				this.quantityOnHand = dto.quantityOnHand;
			}
			if (typeof dto.quantityReserved === 'number') {
				this.quantityReserved = dto.quantityReserved;
			}
			if (typeof dto.lowStockWarning === 'number') {
				this.lowStockWarning = dto.lowStockWarning;
			}
		}
	}

	get quantityAvailable() {
		return this.quantityOnHand - this.quantityReserved;
	}
}
