export default class RentalOperation {
	static deliver = 0;
	static service = 1;
	static pickup = 2;

	static options = [
		{ id: this.deliver, name: 'Deliver' },
		{ id: this.service, name: 'Service' },
		{ id: this.pickup, name: 'Pick up' },
	];

	static display(value) {
		const item = this.options.find(x => x.id === value);
		return item ? item.name : '';
	}

	static isValid(value) {
		return value === this.deliver || value === this.service || value === this.pickup;
	}
}
