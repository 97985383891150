import Customer from './Customer.js';
import CustomerRental from './CustomerRental.js';
import Duration from './Duration.js';
import FileReference from './FileReference.js';
import InspectionReportItem from './InspectionReportItem.js';
import InvoiceDateType from './InvoiceDateType.js';
import TimeType from './TimeType.js';
import WorkOrderInventoryItem from './WorkOrderInventoryItem.js';
import WorkOrderRentalOperation from './WorkOrderRentalOperation.js';
import WorkOrderSchedule from './WorkOrderSchedule.js';
import WorkOrderServiceItem from './WorkOrderServiceItem.js';
import WorkOrderStatus from './WorkOrderStatus.js';
import { getDateTimeFromDto as getDate, getObjectFromDto as getObject, getArrayOfObjectsFromDto as getTypedArray, getValueFromDto as getValue } from './_helpers.js';

export default class WorkOrder {
	constructor(dto) {
		this.id = getValue(dto, 'id', 'number', 0);
		this.number = getValue(dto, 'number', 'number', null);
		this.oldNumber = getValue(dto, 'oldNumber', 'string', null);
		this.createdTimestamp = getDate(dto, 'createdTimestamp', null);
		this.status = getValue(dto, 'status', 'number', WorkOrderStatus.estimate);
		this.customerId = getValue(dto, 'customerId', 'number', null);
		this.customer = getObject(dto, 'customer', Customer, null);
		this.customerLocationId = getValue(dto, 'customerLocationId', 'number', null);
		// define the work
		this.serviceItems = getTypedArray(dto, 'serviceItems', WorkOrderServiceItem, []);
		this.inventoryItems = getTypedArray(dto, 'inventoryItems', WorkOrderInventoryItem, []);
		this.notes = getValue(dto, 'notes', 'string', null);
		this.invoiceNotes = getValue(dto, 'invoiceNotes', 'string', null);
		this.attachments = getTypedArray(dto, 'attachments', FileReference, []);
		// pricing
		this.subTotal = getValue(dto, 'subTotal', 'number', 0);
		this.tax = getValue(dto, 'tax', 'number', 0);
		this.interest = getValue(dto, 'interest', 'number', 0);
		this.total = getValue(dto, 'total', 'number', 0);
		this.isPaid = getValue(dto, 'isPaid', 'boolean', false);
		this.paymentId = getValue(dto, 'paymentId', 'number', null);
		this.paymentDueDate = getDate(dto, 'paymentDueDate', null);
		this.payment = null;
		// reminder
		this.reminderDate = getDate(dto, 'reminderDate', null);
		this.reminderInterval = getObject(dto, 'reminderInterval', Duration, null);
		this.reminderDismissed = getValue(dto, 'reminderDismissed', 'boolean', false);
		this.reminderNotes = getValue(dto, 'reminderNotes', 'string', null);
		// repeating work order schedule
		this.scheduleId = getValue(dto, 'scheduleId', 'number', null);
		this.schedule = getObject(dto, 'schedule', WorkOrderSchedule, null);
		// dispatch
		this.user = null;
		this.userId = getValue(dto, 'userId', 'number', null);
		this.vehicle = null;
		this.vehicleId = getValue(dto, 'vehicleId', 'number', null);
		this.scheduledStartTime = getDate(dto, 'scheduledStartTime', null);
		this.scheduledStartTimeType = getValue(dto, 'scheduledStartTimeType', 'number', TimeType.anyTime);
		this.scheduledStartTimeNotes = getValue(dto, 'scheduledStartTimeNotes', 'string', null);
		this.invoiceDate = getDate(dto, 'invoiceDate', null);
		this.invoiceDateType = getValue(dto, 'invoiceDateType', 'number', InvoiceDateType.completion);
		// the time the actual work started
		this.workStartTime = getDate(dto, 'workStartTime', null);
		this.requireInspection = getValue(dto, 'requireInspection', 'boolean', false);
		this.inspectionReport = getTypedArray(dto, 'inspectionReport', InspectionReportItem, []);
		this.wasteTypeCollectedId = getValue(dto, 'wasteTypeCollectedId', 'number', null);
		this.wasteTypeCollected = null;
		this.amountCollected = getValue(dto, 'amountCollected', 'number', null);
		this.wasteDisposalId = getValue(dto, 'wasteDisposalId', 'number', null);
		// rental
		this.rentalOperations = getTypedArray(dto, 'rentalOperations', WorkOrderRentalOperation, []);
		this.customerRental = getObject(dto, 'customerRental', CustomerRental, null);
		this.customerRentalId = getValue(dto, 'customerRentalId', 'number', null);

		Object.defineProperty(this, 'customer', { enumerable: false });
		// Object.defineProperty(this, 'isPaid', { enumerable: false });
		// Object.defineProperty(this, 'paymentId', { enumerable: false });
		Object.defineProperty(this, 'payment', { enumerable: false });
		// Object.defineProperty(this, 'reminderDate', { enumerable: false });
		Object.defineProperty(this, 'user', { enumerable: false });
		Object.defineProperty(this, 'vehicle', { enumerable: false });
		Object.defineProperty(this, 'wasteTypeCollected', { enumerable: false });
		// Object.defineProperty(this, 'wasteDisposalId', { enumerable: false });
		Object.defineProperty(this, 'customerRental', { enumerable: false });
	}

	get customerLocation() {
		if (this.customerLocationId > 0 && this.customer && Array.isArray(this.customer.locations)) {
			return this.customer.locations.find(x => x.id === this.customerLocationId);
		} else {
			return null;
		}
	}

	get hasSeparateBillingContact() {
		if (!this.customer || !this.customerLocation) { return false; }
		return !this.customerLocation.contacts.includes(this.customer.billingContactId);
	}

	get billingContact() {
		if (!this.customer) { return null; }
		return this.customer.contacts.find(x => x.id === this.customer.billingContactId);
	}

	get locationContacts() {
		if (!this.customer || !this.customerLocation) { return []; }
		return this.customer.contacts.filter(x => this.customerLocation.contacts.includes(x.id));
	}

	isValidUser(absentUsers) {
		return this.status === WorkOrderStatus.invoice || (this.status === WorkOrderStatus.workOrder && (this.userId !== null && !absentUsers.includes(this.userId) && this.user && this.userId === this.user.id && this.user.active && this.user.operator));
	}

	isValidVehicle() {
		return this.status === WorkOrderStatus.invoice || (this.status === WorkOrderStatus.workOrder && (this.vehicleId !== null && this.vehicle && this.vehicleId === this.vehicle.id && this.vehicle.active));
	}

	recalculate() {
		let subTotal = 0;
		for (const item of this.serviceItems) {
			subTotal += item.totalPrice;
		}
		for (const item of this.inventoryItems) {
			subTotal += item.totalPrice;
		}

		this.subTotal = subTotal;
		this.tax = 0; // TODO: integrate tax in the future
		this.interest = 0;
		this.total = this.subTotal + this.tax + this.interest;
	}
}

const dtioProperties = new Set([
	'id',
	'status',
	'customerId',
	'customerLocationId',
	'serviceItems',
	'inventoryItems',
	'notes',
	'invoiceNotes',
	'attachments',
	'reminderInterval',
	'reminderDismissed',
	'reminderNotes',
	'schedule',
	'userId',
	'vehicleId',
	'scheduledStartTime',
	'scheduledStartTimeType',
	'scheduledStartTimeNotes',
	'workStartTime',
	'requireInspection',
	'inspectionReport',
	'wasteTypeCollectedId',
	'amountCollected',
	'invoiceDate',
	'invoiceDateType',
	'rentalOperations',
	'customerRental',
]);

WorkOrder.makeDtio = function (dto) {
	const dtio = Object.assign({}, dto);
	for (const key of Object.keys(dtio)) {
		if (!dtioProperties.has(key)) {
			delete dtio[key];
		}
	}
	if (dto.customerRental instanceof CustomerRental) {
		dtio.customerRental = CustomerRental.makeDtio(dto.customerRental);
	}
	const dtio2 = JSON.parse(JSON.stringify(dtio));
	if (Array.isArray(dtio2.rentalOperations)) {
		for (const x of dtio2.rentalOperations) {
			if (Array.isArray(x.rentalItems)) {
				for (const y of x.rentalItems) {
					if (y.rentalItemId === null) {
						y.rentalItemId = 0;
					}
				}
			}
		}
	}
	return dtio2
}
