import InspectionResponse from './InspectionResponse.js';

export default class InspectionReportItem {
	constructor(dto) {
		this.id = 0;
		this.inspectionItemId = null;
		this.response = null;
		this.notes = null;
		this.sortOrder = 0;
		this.inspectionItem = null;
		Object.defineProperty(this, 'inspectionItem', { enumerable: false });

		if (typeof dto === 'object' && dto !== null) {
			if (typeof dto.id === 'number') {
				this.id = dto.id;
			}
			if (typeof dto.inspectionItemId === 'number') {
				this.inspectionItemId = dto.inspectionItemId;
			}
			if (typeof dto.response === 'number' && InspectionResponse.isValid(dto.response)) {
				this.response = dto.response;
			}
			if (typeof dto.notes === 'string') {
				this.notes = dto.notes;
			}
			if (typeof dto.sortOrder === 'number') {
				this.sortOrder = dto.sortOrder;
			}
		}
	}
}
