import { getDateTimeFromDto as getDate, getObjectFromDto as getObject, getArrayOfObjectsFromDto as getTypedArray, getValueFromDto as getValue } from './_helpers.js';
import Customer, { rawPlaceholder as rawCustomerPlacholder } from './Customer.js';
import CustomerRentalNeed from './CustomerRentalNeed.js';
import RentalItemSchedule from './RentalItemSchedule.js';

export default class CustomerRental {
	constructor(dto) {
		this.id = getValue(dto, 'id', 'number', 0);
		this.customerId = getValue(dto, 'customerId', 'number', null);
		this.customerLocationId = getValue(dto, 'customerLocationId', 'number', null);
		this.customer = getObject(dto, 'customer', Customer, null);
		this.needs = getTypedArray(dto, 'needs', CustomerRentalNeed, []);
		this.schedules = getTypedArray(dto, 'schedules', RentalItemSchedule, []);
		this.startDate = getDate(dto, 'startDate', null);
		this.endDate = getDate(dto, 'endDate', null);
		this.isStarted = getValue(dto, 'isStarted', 'boolean', false);
		this.isEnded = getValue(dto, 'isEnded', 'boolean', false);
		Object.defineProperty(this, 'customer', { enumerable: false });
	}

	get customerLocation() {
		if (this.customerLocationId > 0 && this.customer && Array.isArray(this.customer.locations)) {
			return this.customer.locations.find(x => x.id === this.customerLocationId);
		} else {
			return null;
		}
	}

	get hasSeparateBillingContact() {
		if (!this.customer || !this.customerLocation) { return false; }
		return !this.customerLocation.contacts.includes(this.customer.billingContactId);
	}

	get billingContact() {
		if (!this.customer) { return null; }
		return this.customer.contacts.find(x => x.id === this.customer.billingContactId);
	}

	get locationContacts() {
		if (!this.customer || !this.customerLocation) { return []; }
		return this.customer.contacts.filter(x => this.customerLocation.contacts.includes(x.id));
	}
}

const dtioProperties = new Set([
	'id',
	'startDate',
	'endDate'
]);

CustomerRental.makeDtio = function (dto) {
	const dtio = Object.assign({}, dto);
	for (const key of Object.keys(dtio)) {
		if (!dtioProperties.has(key)) {
			delete dtio[key];
		}
	}
	return JSON.parse(JSON.stringify(dtio));
}

export const rawPlaceholder = {
	customerId: -1,
	customerLocationId: -1,
	customer: rawCustomerPlacholder,
	needs: [{
		rentalItemTypeId: -1,
		quantity: 1,
	}],
	startDate: new Date().toISOString(),
	endDate: new Date().toISOString(),
};
export const placeholder = Object.freeze(new CustomerRental(rawPlaceholder));
